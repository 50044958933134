import { createSlice } from '@reduxjs/toolkit';

import { ResultCity } from '../../types/points';
import { ResultPointWithFilters } from '../../types/points';
import { Types } from '../../pages/ecom_points/logistiacian_points/enums';

const initialState = {
  selectedCity: {
    name: 'Москва',
    national_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    uid: '3973fa4fc226438286aab2743197e4b2',
    country: {
      abbreviation: 'RU',
      name: 'РОССИЯ',
      uid: '500a1bb25e0c4ce4a977007ee92ba7d0'
    },
    region: {
      name: 'Москва',
      national_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      uid: '22af41c5c0ed421cb112679e304772e7'
    }
  } as ResultCity,
  departments: [] as ResultPointWithFilters[],
  postamats: [] as ResultPointWithFilters[],
  filterDepartments: [] as ResultPointWithFilters[],
  filterPostamats: [] as ResultPointWithFilters[],
  address: '',
  pointsCount: 0,
  postamatsPage: 1,
  departmentsPage: 1,
  departmentsPagesCount: 1,
  postamatsPagesCount: 1,
  brands: [] as string[],
  pointTypes: [Types.Department, Types.Postamat] as number[]
};

const pointsMap = createSlice({
  name: 'pointsMap',
  initialState,
  reducers: {
    setSelectedCity(state, action) {
      state.pointsCount = 0;
      state.postamatsPagesCount = 1;
      state.departmentsPagesCount = 1;
      state.postamatsPage = 1;
      state.departmentsPage = 1;
      state.address = '';
      state.postamats = [];
      state.departments = [];
      state.filterDepartments = [];
      state.filterPostamats = [];
      state.selectedCity = action.payload;
    },
    // clearState(state) {
    //   state = {
    //     selectedCity: {
    //       name: 'Москва',
    //       national_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    //       uid: '3973fa4fc226438286aab2743197e4b2',
    //       country: {
    //         abbreviation: 'RU',
    //         name: 'РОССИЯ',
    //         uid: '500a1bb25e0c4ce4a977007ee92ba7d0'
    //       },
    //       region: {
    //         name: 'Москва',
    //         national_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    //         uid: '22af41c5c0ed421cb112679e304772e7'
    //       }
    //     } as ResultCity,
    //     departments: [] as ResultPointWithFilters[],
    //     postamats: [] as ResultPointWithFilters[],
    //     filterDepartments: [] as ResultPointWithFilters[],
    //     filterPostamats: [] as ResultPointWithFilters[],
    //     address: '',
    //     pointsCount: 0,
    //     postamatsPage: 1,
    //     departmentsPage: 1,
    //     departmentsPagesCount: 1,
    //     postamatsPagesCount: 1,
    //     brands: [] as string[],
    //     pointTypes: [Types.Department, Types.Postamat] as number[]
    //   };
    // },
    setDepartments(state, action) {
      state.departments = action.payload;
      state.filterDepartments = action.payload;
    },
    setFilteredDepartments(state) {
      state.filterDepartments = state.departments.filter(
        (item) => state.brands.includes(item.brand) && state.pointTypes.includes(Types.Department)
      );
    },
    setFilteredPostamats(state) {
      state.filterPostamats = state.postamats.filter(
        (item) => state.brands.includes(item.brand) && state.pointTypes.includes(Types.Postamat)
      );
    },
    setAllBrands(state, action) {
      state.brands = action.payload;
    },
    setBrands(state, action) {
      const prevCopy = state.brands.slice();
      const isChecked = prevCopy.includes(action.payload);

      if (isChecked) {
        state.brands = prevCopy.filter((item: string) => item !== action.payload);
        return;
      }

      prevCopy.push(action.payload);
      state.brands = prevCopy;
    },
    setPointTypes(state, action) {
      const prevCopy = state.pointTypes.slice();
      const isChecked = prevCopy.includes(action.payload);

      if (isChecked) {
        state.pointTypes = prevCopy.filter((item: number) => item !== action.payload);
        return;
      }

      prevCopy.push(action.payload);
      state.pointTypes = prevCopy;
    },
    setPostamats(state, action) {
      state.postamats = action.payload;
      state.filterPostamats = action.payload;
    },
    setAddress(state, action) {
      state.address = action.payload;
    },
    setPostamatsPage(state, action) {
      state.postamatsPage = action.payload;
    },
    setDepartmentsPage(state, action) {
      state.departmentsPage = action.payload;
    },
    setDepartmentsPagesCount(state, action) {
      state.departmentsPagesCount = action.payload;
    },
    setPostamatsPagesCount(state, action) {
      state.postamatsPagesCount = action.payload;
    },
    setPointsCount(state) {
      state.pointsCount = state.filterPostamats.length + state.filterDepartments.length;
    }
  }
});

export default pointsMap.reducer;

export const {
  setAllBrands,
  // clearState,
  setFilteredDepartments,
  setFilteredPostamats,
  setPointTypes,
  setBrands,
  setSelectedCity,
  setDepartmentsPagesCount,
  setPostamatsPagesCount,
  setDepartmentsPage,
  setDepartments,
  setPostamatsPage,
  setPostamats,
  setAddress,
  setPointsCount
} = pointsMap.actions;
