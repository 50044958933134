import React, { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { useQueryNavigate } from 'hooks/useQueryNavigate';

interface ILogoOmnic {
  p?: number;
}

const LogoOmnic: FC<ILogoOmnic> = ({ p }) => {
  const navigate = useQueryNavigate();
  const theme = useTheme();
  return (
    <Grid onClick={() => navigate('/dashboard')} pr={4} pl={4} p={p}>
      <Typography display={'inline'} sx={{ color: theme.palette.primary.dark, fontSize: '24px', fontWeight: 700 }}>
        Omnic
      </Typography>{' '}
      <Typography display={'inline'} sx={{ color: theme.palette.primary.main, fontSize: '24px' }}>
        Panel
      </Typography>
    </Grid>
  );
};

export default React.memo(LogoOmnic);
