import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ResultCity } from '../../types/points';
import { Tariff_calc } from '../../types/tariff_calc';

const initialState: Tariff_calc = {
  from: {
    city: '',
    types: [20, 30, 40],
    cityInst: null
  },
  to: {
    city: '',
    types: [30],
    cityInst: null
  },
  sizes: [
    {
      height: 0,
      width: 0,
      length: 0,
      weight: 0,
      size: '',
      isSelect: true
    }
  ],
  cost: '',
  service: 0,
  resultCalc: null,
  currentTariff: null,
  cod_value: 0,
  errorWeight: false,
  errorNotFound: false,
  insurance: false
};

const tariffs = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    changeFromCity(state, action) {
      state.from.city = action.payload;
    },
    changeToCity(state, action) {
      state.to.city = action.payload;
    },
    addErrorWeight(state, action) {
      state.errorWeight = action.payload;
    },
    errorNotFound(state, action) {
      state.errorNotFound = action.payload;
    },
    switchInsurance(state, action) {
      state.insurance = action.payload;
    },
    addCurrentTariff(state, action) {
      if (state.resultCalc) {
        const isTariffCode = state.resultCalc.find((res) => res.code === action.payload);
        state.currentTariff = isTariffCode || null;
      }
    },
    rewriteCurrentTariff(state, action) {
      state.currentTariff = action.payload;
    },
    changeToTypes(state, action) {
      state.to.types = [];
      state.to.types.push(action.payload);
    },
    addCityFrom(state, action: PayloadAction<ResultCity>) {
      state.from.cityInst = action.payload;
    },
    addCityTo(state, action: PayloadAction<ResultCity>) {
      state.to.cityInst = action.payload;
    },
    addSizes(state, action) {
      state.sizes[action.payload.index] = action.payload.sizes;
    },
    changeSizes(state, action) {
      state.sizes = action.payload;
    },
    addHeight(state, action) {
      state.sizes[action.payload.index].height = action.payload.height;
    },
    addWidth(state, action) {
      state.sizes[action.payload.index].width = action.payload.width;
    },
    addLength(state, action) {
      state.sizes[action.payload.index].length = action.payload.length;
    },
    addWeight(state, action) {
      state.sizes[action.payload.index].weight = action.payload.weight;
    },

    addSize(state) {
      state.sizes.push({
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        size: '',
        isSelect: true
      });
    },
    deleteSize(state, action) {
      state.sizes = state.sizes.filter((_, index) => index !== action.payload);
    },
    addCost(state, action) {
      state.cost = action.payload;
    },
    addInsurance(state, action) {
      state.service = action.payload;
    },
    addResult(state, action) {
      state.resultCalc = action.payload;
    },
    swapChecked(state, action) {
      if (state.resultCalc?.[action.payload.index]) {
        state.resultCalc = state.resultCalc?.map((calc) => {
          return { ...calc, checked: false };
        });

        state.resultCalc[action.payload.index].checked = action.payload.checked;
        state.cod_value = state.resultCalc[action.payload.index].delivery_cost;
      }
    },
    addCodValue(state, action) {
      state.cod_value = action.payload;
    },
    swapCities(state) {
      const fromVal = { ...state.to, types: state.from.types };
      state.to = { ...state.from, types: state.to.types };
      state.from = fromVal;
    },
    clearTariffs(state) {
      Object.assign(state, initialState);
    }
  }
});

export default tariffs.reducer;

export const {
  changeFromCity,
  changeToCity,
  changeToTypes,
  addCityFrom,
  addCityTo,
  swapChecked,
  addSizes,
  addHeight,
  addCodValue,
  addWidth,
  addWeight,
  addLength,
  addSize,
  deleteSize,
  addCost,
  swapCities,
  addInsurance,
  addResult,
  clearTariffs,
  addErrorWeight,
  errorNotFound,
  switchInsurance,
  changeSizes,
  addCurrentTariff,
  rewriteCurrentTariff
} = tariffs.actions;
