// material-ui
import { useIntl } from 'react-intl';

// assets
import { QuestionCircleOutlined } from '@ant-design/icons';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { useDispatch, useSelector } from '../../../../../store';
import { changeSelectSettings } from '../../../../../store/reducers/menu';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

interface ISettingTab {
  handleClose: (v: boolean) => void;
}

const SettingTab = ({ handleClose }: ISettingTab) => {
  const { selectSettings } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    dispatch(changeSelectSettings(index));
    window.open('https://omnicampus.atlassian.net/servicedesk/customer/portal/1/group/57', '_blank');
    handleClose(false);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectSettings === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'Support' })} />
      </ListItemButton>
      {/*<ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <UserOutlined />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={intl.formatMessage({ id: 'AccountSettings' })} />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton selected={selectedIndex === 2} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 2)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <LockOutlined />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={intl.formatMessage({ id: 'PrivacyCenter' })} />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton selected={selectedIndex === 3} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <CommentOutlined />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={intl.formatMessage({ id: 'Feedback' })} />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton selected={selectedIndex === 4} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <UnorderedListOutlined />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={intl.formatMessage({ id: 'History' })} />*/}
      {/*</ListItemButton>*/}
    </List>
  );
};

export default SettingTab;
