// types
import { createSlice } from '@reduxjs/toolkit';

import { ResultIntegration } from '../../types/integration';

// initial state
const initialState: ResultIntegration = {
  login: '',
  name: '',
  phone: '',
  email: '',
  type: 0,
  uid: '',
  token: '',
  created_at: '',
  updated_at: ''
};

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    getUserInfoIntegration(state, action) {
      Object.assign(state, action.payload);
    }
  }
});

export default integrations.reducer;

export const { getUserInfoIntegration } = integrations.actions;
