import { useEffect, useState } from 'react';

import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { Box, List, Typography } from '@mui/material';

import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import { useSelector } from '../../../../../store';
import { ShowFrontendMenu } from '../../../../../types/user';
import { findIsAccess } from '../../../../../utils/findIsAccess';

interface Props {
  item: NavItemType;
}

const NavGroup = ({ item }: Props) => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  const { show_frontend_menu } = useSelector((state) => state.user);
  const dashboard = findIsAccess(show_frontend_menu, 'dashboard');
  const ecom_dashboard = findIsAccess(show_frontend_menu, 'ecom_dashboard');
  const ecom_orders = findIsAccess(show_frontend_menu, 'ecom_orders');
  const orders = findIsAccess(show_frontend_menu, 'orders');
  const devices = findIsAccess(show_frontend_menu, 'devices');
  const reports = findIsAccess(show_frontend_menu, 'reports');
  const couriers = findIsAccess(show_frontend_menu, 'couriers');
  const ecom_couriers_call = findIsAccess(show_frontend_menu, 'ecom_couriers_call');
  const requests = findIsAccess(show_frontend_menu, 'requests');
  const ecom_users_integration = findIsAccess(show_frontend_menu, 'ecom_users_integration');
  const tariff_calculator = findIsAccess(show_frontend_menu, 'tariff_calculator');
  const settings = findIsAccess(show_frontend_menu, 'settings');
  const storages = findIsAccess(show_frontend_menu, 'storages');
  const users = findIsAccess(show_frontend_menu, 'users');
  const ecom_points = findIsAccess(show_frontend_menu, 'ecom_points');
  const analytics = findIsAccess(show_frontend_menu, 'analytics');
  const companies = findIsAccess(show_frontend_menu, 'companies');
  const ecom_company = findIsAccess(show_frontend_menu, 'ecom_company');
  const statistics = findIsAccess(show_frontend_menu, 'statistics');
  const location_map = findIsAccess(show_frontend_menu, 'location_map');
  const advertisement = findIsAccess(show_frontend_menu, 'advertisement');
  const knowledgebase = findIsAccess(show_frontend_menu, 'knowledgebase');
  const microservices = findIsAccess(show_frontend_menu, 'microservices');

  const isShowDashboard: ShowFrontendMenu | undefined = dashboard;
  const isShowEcom_Dashboard: ShowFrontendMenu | undefined = ecom_dashboard;
  const isShowOrders: ShowFrontendMenu | undefined = orders;
  const isShowEcom_Orders: ShowFrontendMenu | undefined = ecom_orders;
  const isShowDevices: ShowFrontendMenu | undefined = devices;
  const isShowReports: ShowFrontendMenu | undefined = reports;
  const isShowCouriers: ShowFrontendMenu | undefined = couriers;
  const isShowEcomCouriers: ShowFrontendMenu | undefined = ecom_couriers_call;
  const isShowRequests: ShowFrontendMenu | undefined = requests;
  const isShowEcomUserIntegration: ShowFrontendMenu | undefined = ecom_users_integration;
  const isShowTariffCalculator: ShowFrontendMenu | undefined = tariff_calculator;
  const isShowSettings: ShowFrontendMenu | undefined = settings;
  const isShowStorages: ShowFrontendMenu | undefined = storages;
  const isShowUsers: ShowFrontendMenu | undefined = users;
  const isShowEcomPoints: ShowFrontendMenu | undefined = ecom_points;
  const isShowAnalytics: ShowFrontendMenu | undefined = analytics;
  const isShowCompanies: ShowFrontendMenu | undefined = companies;
  const isShowEcomCompany: ShowFrontendMenu | undefined = ecom_company;
  const isShowStatistics: ShowFrontendMenu | undefined = statistics;
  const isShowLocation: ShowFrontendMenu | undefined = location_map;
  const isShowAdvertisement: ShowFrontendMenu | undefined = advertisement;
  const isShowKnowledgebase: ShowFrontendMenu | undefined = knowledgebase;
  const isShowMicroservices: ShowFrontendMenu | undefined = microservices;
  const [filteredMenu, setFilteredMenu] = useState<any>([]);

  useEffect(() => {
    const filtered = item?.children?.filter((item) => {
      if (item.id === 'default') {
        if (isShowDashboard !== undefined && isShowDashboard?.show) {
          return item;
        }
      }

      if (item.id === 'ecom_dashboard') {
        if (isShowEcom_Dashboard !== undefined && isShowEcom_Dashboard?.show) {
          return item;
        }
      }

      if (item.id === 'order') {
        if (isShowOrders !== undefined && isShowOrders?.show) {
          return item;
        }
      }

      if (item.id === 'miltihub') {
        if (isShowEcom_Orders !== undefined && isShowEcom_Orders?.show) {
          return item;
        }
      }

      if (item.id === 'devices') {
        if (isShowDevices !== undefined && isShowDevices?.show) {
          return item;
        }
      }

      if (item.id === 'reports') {
        if (isShowReports !== undefined && isShowReports?.show) {
          return item;
        }
      }

      if (item.id === 'tariff_calculator') {
        if (isShowTariffCalculator !== undefined && isShowTariffCalculator?.show) {
          return item;
        }
      }

      if (item.id === 'couriers') {
        if (isShowCouriers !== undefined && isShowCouriers?.show) {
          return item;
        }
      }

      if (item.id === 'ecom_couriers') {
        if (isShowEcomCouriers !== undefined && isShowEcomCouriers?.show) {
          return item;
        }
      }

      if (item.id === 'requests') {
        if (isShowRequests !== undefined && isShowRequests?.show) {
          return item;
        }
      }

      if (item.id === 'settings') {
        if (isShowSettings !== undefined && isShowSettings?.show) {
          return item;
        }
      }

      if (item.id === 'ecom_users_integration') {
        if (isShowEcomUserIntegration !== undefined && isShowEcomUserIntegration?.show) {
          return item;
        }
      }

      if (item.id === 'storages') {
        if (isShowStorages !== undefined && isShowStorages?.show) {
          return item;
        }
      }

      if (item.id === 'users') {
        if (isShowUsers !== undefined && isShowUsers?.show) {
          return item;
        }
      }

      if (item.id === 'delivery_points') {
        if (isShowEcomPoints !== undefined && isShowEcomPoints?.show) {
          return item;
        }
      }

      if (item.id === 'analytics') {
        if (isShowAnalytics !== undefined && isShowAnalytics?.show) {
          return item;
        }
      }

      if (item.id === 'companies') {
        if (isShowCompanies !== undefined && isShowCompanies?.show) {
          return item;
        }
      }

      if (item.id === 'statistics') {
        if (isShowStatistics !== undefined && isShowStatistics?.show) {
          return item;
        }
      }

      if (item.id === 'map') {
        if (isShowLocation !== undefined && isShowLocation?.show) {
          return item;
        }
      }

      if (item.id === 'advertisement') {
        if (isShowAdvertisement !== undefined && isShowAdvertisement?.show) {
          return item;
        }
      }

      if (item.id === 'knowledgebase') {
        if (isShowKnowledgebase !== undefined && isShowKnowledgebase?.show) {
          return item;
        }
      }

      if (item.id === 'microservices') {
        if (isShowMicroservices !== undefined && isShowMicroservices?.show) {
          return item;
        }
      }

      // if (item.id === 'news') {
      //   return item;
      // }

      if (item.id === 'profile') {
        return item;
      }

      // if (item.id === 'profile') {
      //   return item?.children?.filter((page) => page.id === 'reset_pass');
      // }
    });

    setFilteredMenu(filtered);
  }, [
    advertisement,
    analytics,
    companies,
    couriers,
    dashboard,
    devices,
    ecom_company,
    ecom_dashboard,
    ecom_orders,
    ecom_points,
    ecom_users_integration,
    isShowAdvertisement,
    isShowAnalytics,
    isShowCompanies,
    isShowCouriers,
    isShowDashboard,
    isShowDevices,
    isShowEcomCompany,
    isShowEcomPoints,
    isShowEcomUserIntegration,
    isShowEcom_Dashboard,
    isShowEcom_Orders,
    isShowKnowledgebase,
    isShowLocation,
    isShowMicroservices,
    isShowOrders,
    isShowReports,
    isShowRequests,
    isShowSettings,
    isShowStatistics,
    isShowStorages,
    isShowUsers,
    item.children,
    knowledgebase,
    location_map,
    microservices,
    orders,
    reports,
    requests,
    settings,
    show_frontend_menu,
    statistics,
    storages,
    users
  ]);

  const navCollapse = filteredMenu?.map((menuItem: NavItemType) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 0 }}>
            {item.caption && (
              <Typography variant="caption" color="secondary">
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: drawerOpen ? 0 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

export default NavGroup;
