// import { useEffect } from 'react';

// project import
// import config from 'config';
// import useAuth from 'hooks/useAuth';
// import { useQueryNavigate } from 'hooks/useQueryNavigate';

// types
import { GuardProps } from 'types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  // const { isLoggedIn } = useAuth();
  // const navigate = useQueryNavigate();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate(config.defaultPath, { replace: true });
  //   }
  // }, [isLoggedIn, navigate]);

  return children;
};

export default GuestGuard;
