// types
import { MenuProps } from 'types/menu';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: MenuProps = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true,
  language: localStorage.getItem('language') || 'en',
  errorMessage: '',
  selectProfile: 0,
  selectSettings: 0
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    isNotAllowed(state, action) {
      state.errorMessage = action.payload;
    },

    changeSelectProfile(state, action) {
      state.selectProfile = action.payload;
    },

    changeSelectSettings(state, action) {
      state.selectSettings = action.payload;
    }
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, isNotAllowed, changeSelectProfile, changeSelectSettings } =
  menu.actions;
