// types
import { createSlice } from '@reduxjs/toolkit';

import { IOrderInfo } from '../../types/ecom_orders';

// initial state
const initialState: IOrderInfo = {
  cost: {
    cod_value: 0,
    declared_cost: 0,
    delivery_cost: 0,
    service_payer: null
  },
  created_at: '',
  delivery_comment: '',
  description: '',
  couriercall: null,
  description_type: 0,
  height: 0,
  length: 0,
  location_from: {
    address: '',
    address_full: '',
    block: '',
    city: '',
    country_code: '',
    house: '',
    latitude: 0,
    longitude: 0,
    office: '',
    point: { code: '', brand: '', type: 0, uid: '' },
    postal_index: '',
    region: '',
    street: '',
    country_name: null
  },
  location_to: {
    address: null,
    address_full: '',
    block: '',
    floor: '',
    city: '',
    country_code: '',
    house: '',
    latitude: 0,
    longitude: 0,
    office: '',
    point: { code: '', brand: '', type: 0, uid: '' },
    postal_index: '',
    region: '',
    street: '',
    country_name: null
  },
  logistician: { name: '', uid: '' },
  logistician_order_number: '',
  logistician_order_uid: '',
  outer_status_name: '',
  order_number: '',
  order_uid: '',
  status_name: null,
  direction_type: 0,
  places: [
    {
      barcode: '',
      height: 0,
      items: [
        {
          articul: '',
          cost: 0,
          cost_vat: 0,
          cost_product: 0,
          height: 0,
          length: 0,
          weight: 0,
          width: 0,
          marking: '',
          name: '',
          provider_inn: '',
          provider_name: '',
          quantity: 0
        }
      ],
      length: 0,
      number: '',
      weight: 0,
      width: 0
    }
  ],
  processing: { order_number: null, order_uid: null },
  receiver: { name: '', phone: '', email: '' },
  return_order: null,
  sender: { name: '', phone: '', email: '' },
  status_code: 0,
  tariff_code: 0,
  updated_at: '',
  vendor_order_number: '',
  vendor_order_uid: '',
  weight: 0,
  width: 0
};

// ==============================|| SLICE - MENU ||============================== //

const request = createSlice({
  name: 'request',
  initialState,
  reducers: {
    addResponseCreateOrder(state, action) {
      Object.assign(state, action.payload.data);
    },
    clearOrder(state) {
      Object.assign(state, initialState);
    }
  }
});

export default request.reducer;

export const { addResponseCreateOrder, clearOrder } = request.actions;
