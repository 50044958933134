import { createSlice } from '@reduxjs/toolkit';

import { AddressCompany, BankAccountCompany } from '../../types/ecom_company';

export interface ICompanyState {
  uid: string;
  inn: string;
  country_code: string;
  name: string;
  subject_type: number;
  website: string;
  description: string;
  phone: string;
  email: string;
  contact: Contact;
  addresses: AddressCompany[];
  bank_accounts: BankAccountCompany[];
  ownership_form: number;
  country_settings: CountrySettings;
  edit: boolean;
}

export interface Contact {
  name: string;
  phone: string;
  email: string;
}

export interface CountrySettings {
  vat_rates: number[];
  default_map_service: string;
  currency: string;
  languages: string[];
}

export interface Extra {}

const initialState: ICompanyState = {
  addresses: [
    {
      area: null,
      city: null,
      house: null,
      office: null,
      region: null,
      street: null,
      type: '',
      zip_code: null
    }
  ],
  bank_accounts: [
    {
      bank_name: '',
      bank_id: '',
      checking_account: '',
      currency: null,
      swift: ''
    }
  ],
  edit: false,
  uid: '',
  inn: '',
  country_code: '',
  name: '',
  subject_type: 0,
  website: '',
  description: '',
  phone: '',
  email: '',
  contact: {
    name: '',
    phone: '',
    email: ''
  },
  ownership_form: 0,
  country_settings: {
    vat_rates: [],
    default_map_service: '',
    currency: '',
    languages: []
  }
};

// ==============================|| SLICE - MENU ||============================== //

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    addAddresses(state, action) {
      if (action.payload.length) {
        state.addresses = action.payload;
      }
    },
    changeType(state, action) {
      state.addresses[action.payload.index].type = action.payload.value;
    },
    changeEdit(state, action) {
      state.edit = action.payload;
    },
    changeRegion(state, action) {
      state.addresses[action.payload.index].region = action.payload.value;
    },
    changeArea(state, action) {
      state.addresses[action.payload.index].area = action.payload.value;
    },
    changeCity(state, action) {
      state.addresses[action.payload.index].city = action.payload.value;
    },
    changeStreet(state, action) {
      state.addresses[action.payload.index].street = action.payload.value;
    },
    changeHouse(state, action) {
      state.addresses[action.payload.index].house = action.payload.value;
    },
    changeOffice(state, action) {
      state.addresses[action.payload.index].office = action.payload.value;
    },
    changeZipCode(state, action) {
      state.addresses[action.payload.index].zip_code = action.payload.value;
    },
    addBankAccounts(state, action) {
      if (action.payload.length) {
        state.bank_accounts = action.payload;
      }
    },
    changeBankName(state, action) {
      state.bank_accounts[action.payload.index].bank_name = action.payload.value;
    },
    changeBankId(state, action) {
      state.bank_accounts[action.payload.index].bank_id = action.payload.value;
    },
    changeCheckingAcc(state, action) {
      state.bank_accounts[action.payload.index].checking_account = action.payload.value;
    },
    changeCurrency(state, action) {
      state.bank_accounts[action.payload.index].currency = action.payload.value;
    },
    changeSwift(state, action) {
      state.bank_accounts[action.payload.index].swift = action.payload.value;
    },
    addNewBankAcc(state, action) {
      state.bank_accounts.push(action.payload);
    },
    removeBankAcc(state, action) {
      state.bank_accounts = state.bank_accounts.filter((_, index) => index !== action.payload);
    }
  }
});

export default company.reducer;

export const {
  addAddresses,
  changeType,
  addBankAccounts,
  changeRegion,
  changeArea,
  changeCity,
  changeStreet,
  changeHouse,
  changeOffice,
  changeZipCode,
  changeBankName,
  changeBankId,
  changeCheckingAcc,
  changeCurrency,
  changeSwift,
  addNewBankAcc,
  removeBankAcc,
  changeEdit
} = company.actions;
