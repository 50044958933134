import { createSlice } from '@reduxjs/toolkit';

import { Extra } from '../../types/order-types';

interface OrderExtra {
  extra: Extra | null;
}

// initial state
const initialState: OrderExtra = {
  extra: null
};

const orderExtra = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    getExtraInfo(state, action: { payload: Extra }) {
      state.extra = action.payload;
    }
  }
});

export default orderExtra.reducer;

export const { getExtraInfo } = orderExtra.actions;
