// material-ui
import { useIntl } from 'react-intl';

import { useQueryNavigate } from 'hooks/useQueryNavigate';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// assets
import { EditOutlined, LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from '../../../../../store';
import { changeEdit } from '../../../../../store/reducers/company';
import { changeSelectProfile } from '../../../../../store/reducers/menu';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleClose: (v: boolean) => void;
}

const ProfileTab = ({ handleLogout, handleClose }: Props) => {
  const navigate = useQueryNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectProfile } = useSelector((state) => state.menu);

  const toChangePassword = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    dispatch(changeSelectProfile(index));
    navigate('/apps/profiles/account/password');
    handleClose(false);
  };

  const toProfile = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    dispatch(changeSelectProfile(index));
    dispatch(changeEdit(false));
    navigate('/apps/profiles/account/ecom_company');
    handleClose(false);
  };

  const toProfileEdit = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    dispatch(changeSelectProfile(index));
    dispatch(changeEdit(true));
    navigate('/apps/profiles/account/ecom_company');
    handleClose(false);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectProfile === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => toProfile(event, 0)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'ShowProfile' })} />
      </ListItemButton>

      <ListItemButton selected={selectProfile === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => toProfileEdit(event, 1)}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'EditProfile' })} />
      </ListItemButton>

      <ListItemButton selected={selectProfile === 2} onClick={(event: React.MouseEvent<HTMLDivElement>) => toChangePassword(event, 2)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'ResetPass' })} />
      </ListItemButton>

      <ListItemButton selected={selectProfile === 3} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'Logout' })} />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
