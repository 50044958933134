// types
import { createSlice } from '@reduxjs/toolkit';

interface IErrors {
  errorAuth: string | JSX.Element;
  errorCost: string | JSX.Element;
  errorQuantity: string | JSX.Element;
}

// initial state
const initialState: IErrors = {
  errorAuth: '',
  errorCost: '',
  errorQuantity: ''
};

// ==============================|| SLICE - MENU ||============================== //

const errors = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    isErrorAuthorization(state, action) {
      state.errorAuth = action.payload;
    },
    isErrorCost(state, action) {
      state.errorCost = action.payload;
    },
    isErrorQuantity(state, action) {
      state.errorQuantity = action.payload;
    }
  }
});

export default errors.reducer;

export const { isErrorAuthorization, isErrorQuantity, isErrorCost } = errors.actions;
