import { ChangeEvent } from 'react';

import useConfig from 'hooks/useConfig';
import { ThemeMode } from 'types/config';
// project import
import MainCard from 'components/MainCard';
// material-ui
import { useTheme } from '@mui/material/styles';
import darkLayout from 'assets/images/customization/dark.svg';
// assets
import defaultLayout from 'assets/images/customization/default.svg';
import { CardMedia, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../../../store';
import { useChangeMode } from '../../../../../hooks/useChangeMode';

// ==============================|| CUSTOMIZATION - MODE ||============================== //

const ThemeModeLayout = () => {
  const theme = useTheme();

  const { mode, onChangeMode } = useConfig();
  const user = useSelector((state) => state.user);
  const modeLayout = user.personal_account_settings?.theme;
  const { i18n } = useConfig();
  const { changeUserMode } = useChangeMode();

  const handleModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (modeLayout) {
      changeUserMode(i18n, { ...modeLayout, theme_mode: event.target.value });
    } else {
      changeUserMode(i18n, {
        theme_layout: 'ltr',
        theme_mode: event.target.value,
        color_scheme: 'default',
        layout_width: 'container',
        font_family: 'public-sans'
      });
    }

    onChangeMode(event.target.value as ThemeMode);
  };

  return (
    <RadioGroup row aria-label="payment-card" name="payment-card" value={mode} onChange={handleModeChange}>
      <Grid container spacing={1.75} sx={{ ml: 0 }}>
        <Grid item>
          <FormControlLabel
            control={<Radio value="light" sx={{ display: 'none' }} />}
            sx={{ display: 'flex', '& .MuiFormControlLabel-label': { flex: 1 } }}
            label={
              <MainCard
                content={false}
                sx={{ bgcolor: mode === 'light' ? 'primary.lighter' : 'secondary.lighter', p: 1 }}
                border={false}
                {...(mode === 'light' && { boxShadow: true, shadow: theme.customShadows.primary })}
              >
                <Stack spacing={1.25} alignItems="center">
                  <CardMedia component="img" src={defaultLayout} alt="Vertical" sx={{ borderRadius: 1, width: 64, height: 64 }} />
                  <Typography variant="caption">Light</Typography>
                </Stack>
              </MainCard>
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Radio value="dark" sx={{ display: 'none' }} />}
            sx={{ display: 'flex', '& .MuiFormControlLabel-label': { flex: 1 } }}
            label={
              <MainCard
                content={false}
                sx={{ bgcolor: mode === 'dark' ? 'primary.lighter' : 'secondary.lighter', p: 1 }}
                border={false}
                {...(mode === 'dark' && { boxShadow: true, shadow: theme.customShadows.primary })}
              >
                <Stack spacing={1.25} alignItems="center">
                  <CardMedia component="img" src={darkLayout} alt="Vertical" sx={{ borderRadius: 1, width: 64, height: 64 }} />
                  <Typography variant="caption">Dark</Typography>
                </Stack>
              </MainCard>
            }
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default ThemeModeLayout;
