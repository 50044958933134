import 'assets/third-party/react-table.css';
import { BrowserRouter } from 'react-router-dom';
// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ReactDOM from 'react-dom/client';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';

// project import
import App from './App';

// load mock apis
import '_api';
// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
// apex-chart
import 'assets/third-party/apex-chart.css';
import 'slick-carousel/slick/slick-theme.css';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>
);
