import { AxiosResponse } from 'axios';

import { Root } from '../types/root';
import { DataAuth } from '../types/auth';
import { AccThemes, IUser } from '../types/user';
import { axiosAuth, axiosCC, CCApiType } from './axios';

class Authorization {
  async signIn(login: string, password: string): Promise<AxiosResponse<Root<DataAuth>>> {
    return await axiosAuth.post<Root<DataAuth>>(`/login`, { login, password }, { headers: { 'Content-type': 'application/json' } });
  }

  async signInSession(session: string): Promise<AxiosResponse<Root<DataAuth>>> {
    return await axiosAuth.request<Root<DataAuth>>({
      method: 'GET',
      url: `/authentication`,
      params: { token: session },
      headers: { 'Content-type': 'application/json' }
    });
  }

  async signOut(): Promise<AxiosResponse<Root<DataAuth>>> {
    return await axiosAuth.post<Root<DataAuth>>(`/logout`, {}, { headers: { 'Content-type': 'application/json' } });
  }

  async getUserData(): Promise<AxiosResponse<Root<IUser>>> {
    return await axiosCC.get<Root<IUser>>(`${CCApiType}/users/current/`, {
      headers: { 'Content-type': 'application/json' }
    });
  }

  async changeUserData(personal_account_settings: {
    theme: AccThemes;
    lang: 'en' | 'ru' | 'uk' | 'he' | 'ar';
  }): Promise<AxiosResponse<Root<IUser>>> {
    return await axiosCC.patch<Root<IUser>>(
      `${CCApiType}/users/current/`,
      { personal_account_settings },
      {
        headers: { 'Content-type': 'application/json' }
      }
    );
  }

  async updateUserPassword(old_password: string, new_password: string) {
    return await axiosCC.put(
      `${CCApiType}/users/current/password/`,
      {
        old_password,
        new_password
      },
      {
        headers: { 'Content-type': 'application/json' }
      }
    );
  }
}

export default new Authorization();
