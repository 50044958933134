// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = () => {
  return {
    orderNumberReq: '',
    location_from: null,
    dateCourier: '',
    from: '',
    to: '',
    FIO: '',
    phone: '',
    email: '',
    pointType: 0,
    point: '',
    country: '',
    region: '',
    city: {
      name: undefined,
      national_id: '',
      uid: '',
      region: {
        name: '',
        national_id: '',
        uid: '',
        country: {
          name: '',
          abbreviation: '',
          uid: ''
        }
      },
      country: {
        name: '',
        abbreviation: '',
        uid: ''
      }
    },
    office: '',
    address: '',
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
    places: 0,
    selectedUid: undefined,
    isCourierCall: true,
    uids: [] as string[],
    errFIO: false,
    errPhone: false,
    errEmail: false,
    errHeight: false,
    errWidth: false,
    errLength: false,
    errWeight: false,
    errFrom: true,
    errTo: true,
    errFromLessTo: false
  };
};

// ==============================|| SLICE - MENU ||============================== //

const createRequest = createSlice({
  name: 'forCreateRequiest',
  initialState,
  reducers: {
    setOrderNumForRequest(state, action) {
      state.orderNumberReq = action.payload;
    },
    setSelectedUid(state, action) {
      state.selectedUid = action.payload;
    },
    addDataCourier(state, action) {
      state.dateCourier = action.payload;
    },
    addFromTo(state, action) {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
    setCourierCall(state, action) {
      state.isCourierCall = action.payload;
    },
    addFIO(state, action) {
      state.FIO = action.payload;
    },
    addPhone(state, action) {
      state.phone = action.payload;
    },
    addEmail(state, action) {
      state.email = action.payload;
    },
    addCountry(state, action) {
      state.country = action.payload;
    },
    addRegion(state, action) {
      state.region = action.payload;
    },
    addCity(state, action) {
      state.city = action.payload;
    },
    addAddress(state, action) {
      state.address = action.payload;
    },
    addPoint(state, action) {
      state.point = action.payload;
    },
    addPointType(state, action) {
      state.pointType = action.payload;
    },
    addOffice(state, action) {
      state.office = action.payload;
    },
    addUids(state, action) {
      if (action.payload.uid) {
        const uid = state.uids.find((uid: string) => uid === action.payload.uid);
        const V = +action.payload.height * +action.payload.width * +action.payload.length;
        const side = Math.floor(Math.cbrt(V));

        if (!uid) {
          state.uids.push(action.payload.uid);
          state.weight += Number(action.payload.weight);
          state.height += +side;
          state.width += +side;
          state.length += +side;
          state.places += action.payload.places;
        } else {
          state.uids = state.uids.filter((uid) => uid !== action.payload.uid);
          state.weight -= Number(action.payload.weight);
          state.height -= +side;
          state.width -= +side;
          state.length -= +side;
          state.places -= action.payload.places;
        }
      }
    },

    addUid(state, action) {
      const V = +action.payload.height * +action.payload.width * +action.payload.length;
      const side = Math.floor(Math.cbrt(V));
      state.uids.push(action.payload.uid);
      state.weight += Number(action.payload.weight);
      state.height += +side;
      state.width += +side;
      state.length += +side;
      state.places += action.payload.places;
    },

    deleteAllUids(state) {
      state.uids = [];
      state.weight = 0;
      state.height = 0;
      state.width = 0;
      state.length = 0;
      state.places = 0;
    },
    addHeight(state, action) {
      state.height = Number(action.payload);
    },
    addWidth(state, action) {
      state.width = Number(action.payload);
    },
    addLength(state, action) {
      state.length = Number(action.payload);
    },
    addWeight(state, action) {
      state.weight = Number(action.payload);
    },
    isErrorFIO(state, action) {
      state.errFIO = action.payload;
    },
    isErrorPhone(state, action) {
      state.errPhone = action.payload;
    },
    isErrorHeight(state, action) {
      state.errHeight = action.payload;
    },
    isErrorWidth(state, action) {
      state.errWidth = action.payload;
    },
    isErrorLength(state, action) {
      state.errLength = action.payload;
    },
    isErrorWeight(state, action) {
      state.errWeight = action.payload;
    },
    isErrorFrom(state, action) {
      state.errFrom = action.payload;
    },
    isErrorTo(state, action) {
      state.errTo = action.payload;
    },
    fromLessTo(state, action) {
      state.errFromLessTo = action.payload;
    },
    clearUids(state) {
      state.uids = [];
      state.weight = 0;
      state.width = 0;
      state.height = 0;
      state.length = 0;
      state.places = 0;
    },
    addLocationFrom(state, action) {
      state.location_from = action.payload;
    },
    clearRequest(state) {
      Object.assign(state, initialState());
    }
  }
});

export default createRequest.reducer;

export const {
  setOrderNumForRequest,
  addDataCourier,
  addFromTo,
  addFIO,
  addPhone,
  addEmail,
  addOffice,
  addCity,
  addAddress,
  setSelectedUid,
  setCourierCall,
  addUids,
  addHeight,
  addWidth,
  addLength,
  addWeight,
  clearRequest,
  addUid,
  deleteAllUids,
  isErrorFIO,
  isErrorPhone,
  clearUids,
  isErrorFrom,
  isErrorTo,
  fromLessTo,
  addLocationFrom
} = createRequest.actions;
