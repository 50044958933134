enum Types {
  Postamat = 30,
  Department = 20
}

enum Brands {
  Omnic = 'Omnic',
  PickPoint = 'PickPoint',
  Halva = 'Halva',
  CDEK = 'СДЭК ООО'
}

enum Point {
  PVZ = 'pvz',
  Postamat = 'postamat',
  All = 'all'
}

export { Types, Brands, Point };
