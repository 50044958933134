// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  DashboardOutlined,
  GlobalOutlined,
  GoldOutlined,
  MoneyCollectOutlined,
  OrderedListOutlined,
  RadarChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  GlobalOutlined,
  RadarChartOutlined,
  MoneyCollectOutlined,
  OrderedListOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'ecom_dashboard',
      title: <FormattedMessage id="Monitoring" />,
      type: 'item',
      url: '/ecom_dashboard',
      breadcrumbs: false,
      icon: icons.DashboardOutlined
    },
    {
      id: 'miltihub',
      title: <FormattedMessage id="Orders" />,
      type: 'collapse',
      icon: icons.OrderedListOutlined,
      children: [
        {
          id: 'order_list',
          title: <FormattedMessage id="List" />,
          type: 'item',
          url: '/ecom_orders'
        },
        {
          id: 'create',
          title: <FormattedMessage id="Create" />,
          type: 'item',
          url: '/ecom_orders/create_orders'
        },
        {
          id: 'import',
          title: <FormattedMessage id="Import" />,
          type: 'item',
          url: '/ecom_orders/import_order'
        }
        // {
        //   id: 'Tracking',
        //   title: <FormattedMessage id="Tracking" />,
        //   type: 'item',
        //   url: '/ecom_orders/tracking'
        // }
      ]
    },
    {
      id: 'tariff',
      title: <FormattedMessage id="Tariffs" />,
      type: 'item',
      url: '/tariff',
      icon: icons.MoneyCollectOutlined
    }
    // {
    //   id: 'map',
    //   title: <FormattedMessage id="MapLocation" />,
    //   type: 'item',
    //   url: '/map',
    //   icon: icons.GlobalOutlined
    // },
    // {
    //   id: 'analytics',
    //   title: <FormattedMessage id="Analytic" />,
    //   type: 'item',
    //   url: '/analytics',
    //   icon: icons.GoldOutlined
    // }
  ]
};

export default dashboard;
