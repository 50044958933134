import { I18n } from '../types/config';
import { AccThemes } from '../types/user';
import Authorization from '../api-axios/auth';

export const useChangeMode = () => {
  const changeUserMode = async (lang: I18n, theme: AccThemes) => {
    const person = {
      lang,
      theme
    };

    try {
      await Authorization.changeUserData(person);
    } catch (e) {
      console.debug(e);
    }
  };

  return { changeUserMode };
};
