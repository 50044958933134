import { createSlice } from '@reduxjs/toolkit';

import { Place } from '../../types/cargo_place';

interface IPlace {
  places: Place[];
}

const initialState: IPlace = {
  places: [
    {
      items: [
        {
          name: '',
          articul: '',
          marking: '',
          provider_name: '',
          provider_inn: '',
          height: 0,
          width: 0,
          length: 0,
          weight: 0,
          cost: 0,
          cost_vat: 0,
          quantity: 0
        }
      ],
      barcode: '',
      number: '',
      height: 0,
      width: 0,
      length: 0,
      weight: 0
    }
  ]
};

// ==============================|| SLICE - MENU ||============================== //

const cargo_place = createSlice({
  name: 'cargo_place',
  initialState,
  reducers: {
    setPlace(state, action) {
      state.places = action.payload;
    },
    setItems(state, action) {
      if (state.places[action.payload.indexPlace]) {
        state.places[action.payload.indexPlace].items[action.payload.index] = action.payload.items;
        state.places[action.payload.indexPlace].barcode = action.payload.barcode;
        state.places[action.payload.indexPlace].number = action.payload.number;
        state.places[action.payload.indexPlace].height = action.payload.height;
        state.places[action.payload.indexPlace].width = action.payload.width;
        state.places[action.payload.indexPlace].length = action.payload.length;
        state.places[action.payload.indexPlace].weight = action.payload.weight;
      }
    }
  }
});

export default cargo_place.reducer;

export const { setPlace, setItems } = cargo_place.actions;
