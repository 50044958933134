import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { CSSProperties, ReactElement, useEffect, useState } from 'react';

import { NavItemType } from 'types/menu';
// types
import { OverrideIcon } from 'types/root';
import { useTheme } from '@mui/material/styles';
// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Divider, Grid, Typography } from '@mui/material';
// assets
import { ApartmentOutlined, HomeFilled, HomeOutlined } from '@ant-design/icons';

// project imports
import MainCard from '../MainCard';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const [main, setMain] = useState<NavItemType | undefined>();
  const [item, setItem] = useState<NavItemType>();

  const isHideUi = location.search.includes('hide_ui=true');

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '12px',
    height: '12px',
    color: theme.palette.secondary.main
  };

  useEffect(() => {
    navigation?.items?.map((menu: NavItemType, index: number) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu as { children: NavItemType[]; type?: string });
      }

      return false;
    });
  });

  // only used for component demo breadcrumbs
  if (location.pathname === '/components-overview/breadcrumbs') {
    location.pathname = '/analytics';
  }

  // set active item state
  const getCollapse = (menu: NavItemType) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
        } else if (collapse.type && collapse.type === 'item') {
          if (location.pathname === collapse.url) {
            setMain(menu);
            setItem(collapse);
          }
        }

        return false;
      });
    }
  };

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let mainContent;
  let itemContent;
  let breadcrumbContent: ReactElement = <Typography />;
  let itemTitle: NavItemType['title'] = '';
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main && main.type === 'collapse') {
    CollapseIcon = main.icon ? main.icon : ApartmentOutlined;

    if (typeof CollapseIcon === 'string') {
      mainContent = (
        <Typography component={Link} to={document.location.pathname} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
          {icons && <img src={CollapseIcon} alt="collapse" />}
          {main.title}
        </Typography>
      );
    } else {
      mainContent = (
        <Typography component={Link} to={document.location.pathname} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
          {icons && <CollapseIcon style={iconSX} />}
          {main.title}
        </Typography>
      );
    }
  }

  // items
  if (item && item.type === 'item') {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : ApartmentOutlined;

    if (typeof ItemIcon === 'string') {
      itemContent = (
        <Typography color={theme.palette.grey['900']}>
          {icons && <img src={ItemIcon} alt="collapse" />}
          {itemTitle}
        </Typography>
      );
    } else {
      itemContent = (
        <Typography color={theme.palette.grey['900']}>
          {icons && <ItemIcon style={iconSX} />}
          {itemTitle}
        </Typography>
      );
    }

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
          {...others}
          content={card}
          shadow="none"
        >
          <Grid
            container
            direction={rightAlign ? 'row' : 'column'}
            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
            alignItems={rightAlign ? 'center' : 'flex-start'}
            spacing={1}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h2">{item.title}</Typography>
              </Grid>
            )}
            {!isHideUi && (
              <Grid item>
                <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
                  <Typography component={Link} to="/" color="textSecondary" sx={{ textDecoration: 'none' }}>
                    <HomeOutlined style={{ marginRight: 7 }} />
                    {icons && <HomeOutlined style={iconSX} />}
                    {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
                    {(!icon || icons) && 'Home'}
                  </Typography>
                  {mainContent}
                  {itemContent}
                </MuiBreadcrumbs>
              </Grid>
            )}
            {title && titleBottom && (
              <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
                {!location?.pathname?.includes('ecom_orders/') &&
                  item.id !== 'couriers_create' &&
                  !(location?.pathname?.includes('view') && location?.pathname?.includes('couriers')) &&
                  !(location?.pathname?.includes('logistician_points') && location?.pathname?.includes('/view')) && (
                    <Typography variant="h3">{item.title}</Typography>
                  )}
                {location?.pathname?.includes('/view/') && location?.pathname?.includes('ecom_orders') && (
                  <Typography variant="h3">
                    <FormattedMessage id={'OrderCard'} />
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
