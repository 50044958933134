import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import navigation from 'menu-items';
import { AxiosInstance } from 'axios';
import useConfig from 'hooks/useConfig';
// types
import { RootStateProps } from 'types/root';
// material-ui
import { useTheme } from '@mui/material/styles';
import { openDrawer } from 'store/reducers/menu';
import { useQueryNavigate } from 'hooks/useQueryNavigate';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { Box, Container, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import { LOGOUT } from '../../store/reducers/actions';
import { useDispatch, useSelector } from '../../store';
import { axiosCC, axiosECG } from '../../api-axios/axios';
import { clearUserDate } from '../../store/reducers/user';
import { isErrorAuthorization } from '../../store/reducers/errors';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const intl = useIntl();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const navigate = useQueryNavigate();
  const location = useLocation();
  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { login } = useSelector((state) => state.user);

  const isHideUi = location.search.includes('hide_ui=true');

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch({
        type: LOGOUT
      });

      dispatch(clearUserDate());
      navigate('/login');
    }
  }, [dispatch, isLoggedIn, navigate]);

  const isUnauthorized = (axios: AxiosInstance) => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          dispatch({
            type: LOGOUT
          });

          dispatch(clearUserDate());
          dispatch(isErrorAuthorization(intl.formatMessage({ id: 'EndOfSession' })));
        }

        throw error;
      }
    );
  };

  const isChangeUser = () => {
    const loggedIn = JSON.parse(localStorage.getItem('persist:user') as string).login.replaceAll('"', '');

    if (login && loggedIn && login !== loggedIn) {
      document.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('focus', isChangeUser);
  }, [login]);

  useEffect(() => {
    isUnauthorized(axiosCC);
    isUnauthorized(axiosECG);
  }, [dispatch, location.pathname]);

  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return !isHideUi ? (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {container && (
          <Container
            maxWidth="lg"
            sx={{ px: { xs: 0, sm: 2 }, position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}
          >
            {location.pathname !== '/news' && <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />}
            <Outlet />
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
      <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
      <Outlet />
    </Box>
  );
};

export default MainLayout;
