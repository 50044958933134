import { useMemo } from 'react';

import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

import Profile from './Profile';
import Localization from './Localization';
import MobileSection from './MobileSection';
import Customization from './Customization';

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);
  const localization = useMemo(() => <Localization />, []);
  return (
    <>
      {!matchesXs && <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}></Box>}
      {/*{!matchesXs && megaMenu}*/}
      {!matchesXs && localization}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/*<Notification />*/}
      {/*<Message />*/}
      <Customization />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
