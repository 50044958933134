import AxiosOrigin from 'axios';

export const axiosCC = AxiosOrigin.create({
  baseURL: process.env.REACT_APP_HOST_HUB
});

export const axiosECG = AxiosOrigin.create({
  baseURL: process.env.REACT_APP_HOST_ECOM
});

export const axiosAuth = AxiosOrigin.create({
  baseURL: process.env.REACT_APP_HOST_AUTH
});

export const axiosTariff = AxiosOrigin.create({
  baseURL: process.env.REACT_APP_HOST_TARIFF
});

axiosCC.defaults.withCredentials = true;
axiosECG.defaults.withCredentials = true;
axiosAuth.defaults.withCredentials = true;
axiosTariff.defaults.withCredentials = true;

export const CCApiType = '/api/2.0.0';
export const ECGApiType = '/api/1.0.0/type-1';
export const tariffApiType = '/api/1.0';
