import React from 'react';
import { useIntl } from 'react-intl';

import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Link } from '@mui/material';

import mapIcon from '../../assets/images/icons/map.svg';
import linkOut from '../../assets/images/icons/linkOutBlack.svg';

function Widget() {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Stack sx={{ m: '40px' }}>
      <Typography variant="h5" sx={{ mb: '12px' }}>
        {intl.formatMessage({ id: 'WidgetForEcommerce' })}
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="12px">
        <Stack sx={{ mt: '12px', width: '560px' }} spacing="24px" justifyContent="space-between">
          <Typography sx={{ fontSize: '16px', width: '510px' }}>{intl.formatMessage({ id: 'ProfileWidgetContent' })}</Typography>
          <Stack sx={{ border: `1px solid ${theme.palette.grey['300']}`, p: '16px', borderRadius: '2px' }} direction="row" spacing="12px">
            <Typography color={theme.palette.grey['500']}>{intl.formatMessage({ id: 'WidgetDocumentation' })}</Typography>
            <Link
              href="https://omnicampus.atlassian.net/wiki/spaces/OPD/pages/4791435457/Widget+for+ecommerce"
              target="_blank"
              underline="none"
              color="black"
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: theme.palette.grey['300'],
                height: '40px',
                boxSizing: 'content-box',
                p: '0 16px'
              }}
            >
              <img
                src={linkOut}
                style={{ fill: 'black', width: '12px', marginRight: '8px' }}
                alt={intl.formatMessage({ id: 'Documentation' })}
              />
              <Typography>{intl.formatMessage({ id: 'Documentation' })}</Typography>
            </Link>
          </Stack>
        </Stack>
        <Stack>
          <img style={{ width: '100%' }} src={mapIcon} alt={intl.formatMessage({ id: 'Map' })} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default React.memo(Widget);
