import { useCallback } from 'react';
import { NavigateFunction, NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom';

const HIDE_UI = 'hide_ui';
const REDIRECT_TO = 'redirect_to';

export function useQueryNavigate(): NavigateFunction {
  const [searchParams] = useSearchParams();
  const hideUi = searchParams.get(HIDE_UI);
  const redirectTo = searchParams.get(REDIRECT_TO);
  const navigate = useNavigate();

  return useCallback(
    (to: To | number, options?: NavigateOptions) => {
      if (typeof to === 'number') {
        return navigate(to);
      }

      const search = hideUi && redirectTo ? `?${REDIRECT_TO}=${redirectTo}&${HIDE_UI}=${hideUi}` : '';
      navigate(to + search, options);
    },
    [hideUi, redirectTo, navigate]
  );
}
