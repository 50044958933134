import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import DrawerHeaderStyled from './DrawerHeaderStyled';
import LogoOmnic from '../../../../components/logo/LogoOmnic';
import IconButton from '../../../../components/@extended/IconButton';

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const DrawerHeader = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {open && <LogoOmnic />}
      {!open && (
        <Grid ml={2}>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            sx={{ color: 'text.primary', ml: { xs: -2, lg: -2 } }}
          >
            {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </IconButton>
        </Grid>
      )}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
