// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import chat from './chat';
import auth from './auth';
import menu from './menu';
import user from './user';
import errors from './errors';
import company from './company';
import request from './request';
import cartReducer from './cart';
import calendar from './calendar';
import snackbar from './snackbar';
import tariffs from './tariff_calc';
import points_map from './points_map';
import productReducer from './product';
import orderExtra from './order_extra';
import cargo_place from './cargo_place';
import order_create from './order_create';
import integrations from './integrations';
import createRequest from './for_create_request';

const reducers = combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage
    },
    auth
  ),
  user: persistReducer(
    {
      key: 'user',
      storage
    },
    user
  ),
  order_create,
  integrations,
  chat,
  company: persistReducer(
    {
      key: 'company',
      storage
    },
    company
  ),
  calendar,
  createRequest,
  menu,
  snackbar,
  request,
  cargo_place,
  points_map,
  cart: persistReducer(
    {
      key: 'cart',
      storage
    },
    cartReducer
  ),
  errors,
  product: productReducer,
  tariffs,
  orderExtra
});

export default reducers;
