// import { useEffect } from 'react';

// project import
// import useAuth from 'hooks/useAuth';
// import { useQueryNavigate } from 'hooks/useQueryNavigate';
// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  // const { isLoggedIn } = useAuth();
  // const navigate = useQueryNavigate();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate('login', { replace: true });
  //   }
  // }, [isLoggedIn, navigate]);

  return children;
};

export default AuthGuard;
