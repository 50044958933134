import { useRef, useState } from 'react';

import { I18n } from 'types/config';
import useConfig from 'hooks/useConfig';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { TranslationOutlined } from '@ant-design/icons';
// project import
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { Box, ClickAwayListener, Grid, List, ListItemButton, ListItemText, Paper, Popper, Typography, useMediaQuery } from '@mui/material';

import { useDispatch, useSelector } from '../../../../store';
import { changeLang } from '../../../../store/reducers/user';
import { useChangeMode } from '../../../../hooks/useChangeMode';
// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector((state) => state.user);
  const { i18n, onChangeLocalization } = useConfig();
  const dispatch = useDispatch();
  const { changeUserMode } = useChangeMode();
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const defaultTheme = {
    theme_layout: 'ltr',
    theme_mode: 'light',
    color_scheme: 'default',
    layout_width: 'container',
    font_family: 'public-sans'
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListItemClick = async (lang: I18n) => {
    await changeUserMode(lang, user.personal_account_settings?.theme || defaultTheme);
    onChangeLocalization(lang);
    dispatch(changeLang(lang));
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open localization"
        ref={anchorRef}
        aria-controls={open ? 'localization-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <TranslationOutlined />
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 0 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250
                    }
                  }}
                >
                  {user.country_settings?.languages.length ? (
                    user.country_settings?.languages?.map((lang) => {
                      return (
                        <ListItemButton key={lang} selected={i18n === lang} onClick={() => handleListItemClick(lang)}>
                          <ListItemText
                            primary={
                              <Grid container>
                                <Typography color="textPrimary">
                                  {lang === 'ru' && 'Русский'}
                                  {lang === 'en' && 'English'}
                                  {lang === 'ar' && 'عرب'}
                                  {lang === 'he' && 'עִברִית'}
                                  {lang === 'uk' && 'Український'}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                  {lang === 'ru' && '(Russian)'}
                                  {lang === 'en' && '(UK)'}
                                  {lang === 'ar' && '(Arabic)'}
                                  {lang === 'he' && '(Hebrew)'}
                                  {lang === 'uk' && '(Ukrainian)'}
                                </Typography>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      );
                    })
                  ) : (
                    <ListItemButton selected={i18n === 'en'} onClick={() => handleListItemClick('en')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">English</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (UK)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Localization;
