// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';
import {
  AreaChartOutlined,
  BookOutlined,
  BuildOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  DownCircleOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FormOutlined,
  IdcardOutlined,
  InboxOutlined,
  LineChartOutlined,
  MobileOutlined,
  PaperClipOutlined,
  SettingOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WalletOutlined,
  ProfileOutlined,
  ContainerOutlined,
  CalculatorOutlined
} from '@ant-design/icons';

import { ReactComponent as News } from '../assets/images/news.svg';
// assets
import { ReactComponent as DeliveryPoint } from '../assets/images/delivery_point.svg';

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  MobileOutlined,
  UserOutlined,
  UnorderedListOutlined,
  AreaChartOutlined,
  TableOutlined,
  InboxOutlined,
  BuildOutlined,
  FormOutlined,
  BookOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  DownCircleOutlined,
  EnvironmentOutlined,
  UsergroupAddOutlined,
  WalletOutlined,
  PaperClipOutlined,
  FileDoneOutlined,
  ProfileOutlined,
  ContainerOutlined,
  CalculatorOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget: NavItemType = {
  id: 'group-widget',
  title: <FormattedMessage id="widgets" />,
  type: 'group',
  children: [
    {
      id: 'tariff_calculator',
      title: <FormattedMessage id="DeliveryCalculator" />,
      type: 'item',
      url: '/tariff_calculator',
      icon: CalculatorOutlined
    },
    {
      id: 'ecom_couriers',
      title: <FormattedMessage id="CouriersCall" />,
      type: 'collapse',
      icon: icons.FileDoneOutlined,
      children: [
        { id: 'couriers_list', url: '/couriers', title: <FormattedMessage id="RequestList" />, type: 'item' },
        {
          id: 'couriers_create',
          url: '/couriers/create_couriers_call',
          title: <FormattedMessage id="CreateRequest" />,
          type: 'item'
        }
      ]
    },
    {
      id: 'delivery_points',
      title: <FormattedMessage id="DeliveryPoints" />,
      type: 'item',
      url: '/logistician_points',
      icon: DeliveryPoint
    },
    {
      id: 'reports',
      title: <FormattedMessage id="Reports" />,
      type: 'item',
      url: '/reports',
      icon: icons.TableOutlined
    },
    {
      id: 'billing',
      title: <FormattedMessage id="Billing" />,
      type: 'item',
      url: '/billing',
      icon: icons.TableOutlined
    },
    {
      id: 'profile',
      title: <FormattedMessage id="Profile" />,
      type: 'collapse',
      icon: icons.ProfileOutlined,
      children: [
        // {
        //   id: 'profileInfo',
        //   title: <FormattedMessage id="ProfileInfo" />,
        //   type: 'item',
        //   url: '/apps/profiles/account/basic'
        // },
        {
          id: 'company_info',
          title: <FormattedMessage id="CompanyInfo" />,
          type: 'item',
          url: '/apps/profiles/account/ecom_company'
        },
        {
          id: 'own_locations',
          title: <FormattedMessage id="OwnPoints" />,
          type: 'item',
          url: '/apps/profiles/account/own_points'
        },
        {
          id: 'integration',
          title: <FormattedMessage id="UsersIntegration" />,
          type: 'item',
          url: '/apps/profiles/account/ecom_users_integration'
        },
        {
          id: 'reset_pass',
          title: <FormattedMessage id="ResetPass" />,
          type: 'item',
          url: '/apps/profiles/account/password'
        },
        {
          id: 'widget',
          title: <FormattedMessage id="Widget" />,
          type: 'item',
          url: '/apps/profiles/account/widget'
        }
      ]
    },
    {
      id: 'news',
      title: <FormattedMessage id="News" />,
      type: 'item',
      url: '/news',
      icon: News
    },
    {
      id: 'statistics',
      title: <FormattedMessage id="Statistic" />,
      type: 'item',
      url: '/statistics',
      icon: icons.LineChartOutlined
    },
    {
      id: 'devices',
      title: <FormattedMessage id="Devices" />,
      type: 'item',
      url: '/devices',
      icon: icons.MobileOutlined
    },
    {
      id: 'companies',
      title: <FormattedMessage id="Companies" />,
      type: 'item',
      url: '/companies',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'order',
      title: <FormattedMessage id="Order" />,
      type: 'item',
      url: '/orders',
      icon: icons.InboxOutlined
    },
    {
      id: 'storages',
      title: <FormattedMessage id="Storages" />,
      type: 'item',
      url: '/storages',
      icon: icons.BuildOutlined
    },
    {
      id: 'requests',
      title: <FormattedMessage id="Requests" />,
      type: 'item',
      url: '/requests',
      icon: icons.FormOutlined
    },
    {
      id: 'knowledgebase',
      title: <FormattedMessage id="KnowledgeBase" />,
      type: 'item',
      url: '/knowledgebase',
      icon: icons.BookOutlined
    },
    {
      id: 'settings',
      title: <FormattedMessage id="Settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.SettingOutlined
    }
  ]
};

export default widget;
