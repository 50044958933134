// import { useSelector } from 'react-redux';
// import { FormattedMessage } from 'react-intl';

// import { AvatarGroup } from '@mui/material';
// import avatar1 from 'assets/images/users/avatar-1.png';
// import avatar2 from 'assets/images/users/avatar-2.png';
// import avatar3 from 'assets/images/users/avatar-3.png';
// import avatar4 from 'assets/images/users/avatar-4.png';
import SimpleBar from 'components/third-party/SimpleBar';
// import { Avatar, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

// import NavCard from './NavCard';
import Navigation from './Navigation';
// import MainCard from '../../../../components/MainCard';
// import { RootStateProps } from '../../../../types/root';

const DrawerContent = () => {
  // const theme = useTheme();
  // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // const menu = useSelector((state: RootStateProps) => state.menu);
  // const { drawerOpen } = menu;
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Navigation />
      {/* {drawerOpen && !matchDownMD && <NavCard />} */}
      {/* {drawerOpen && !matchDownMD && (
        <MainCard sx={{ borderRight: '0', borderLeft: '0', borderRadius: '0' }}>
          <Stack spacing={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack>
                  <Typography variant="h5" noWrap>
                    <FormattedMessage id={'ChatSup'} />
                  </Typography>
                  <Typography fontSize={11} variant="caption" color="secondary" noWrap>
                    <FormattedMessage id={'TimeResp'} />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <AvatarGroup sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
                  <Avatar alt="Remy Sharp" src={avatar1} />
                  <Avatar alt="Travis Howard" src={avatar2} />
                  <Avatar alt="Cindy Baker" src={avatar3} />
                  <Avatar alt="Agnes Walker" src={avatar4} />
                </AvatarGroup>
              </Grid>
            </Grid>
            <Button
              size="small"
              variant="contained"
              sx={{ textTransform: 'none' }}
              onClick={() => window.open('https://omnicampus.atlassian.net/servicedesk/customer/portal/1/group/57', '_blank')}
            >
              <FormattedMessage id={'NeedHelp'} />
            </Button>
          </Stack>
        </MainCard>
      )} */}
    </SimpleBar>
  );
};

export default DrawerContent;
