import { FormattedMessage } from 'react-intl';
import { lazy, useCallback, useEffect } from 'react';
import { useRoutes, useSearchParams } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';

import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import useConfig from '../hooks/useConfig';
import Authorization from '../api-axios/auth';
import { LOGIN } from '../store/reducers/actions';
import { useDispatch, useSelector } from '../store';
import { getAllUserData } from '../store/reducers/user';
import { isErrorAuthorization } from '../store/reducers/errors';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================= //

export default function ThemeRoutes() {
  const [searchParams] = useSearchParams();
  const session = searchParams.get('session');

  const dispatch = useDispatch();
  const { onChangeLocalization } = useConfig();
  const language = navigator.language.slice(0, 2);
  const { personal_account_settings } = useSelector((state) => state.user);

  const isSession = useCallback(async () => {
    try {
      await Authorization.signInSession(session as string);

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          session: true
        }
      });

      await Authorization.getUserData().then(({ data }) => dispatch(getAllUserData(data.data)));
    } catch (e) {
      console.debug(e);
      dispatch(isErrorAuthorization(<FormattedMessage id={'NotSession'} />));
    }
  }, [dispatch, session]);

  useEffect(() => {
    if (session) {
      isSession();
    }
  }, [session]);

  useEffect(() => {
    if (personal_account_settings?.lang) {
      onChangeLocalization(personal_account_settings?.lang);
    } else if (language === 'ru') {
      onChangeLocalization(language);
    } else {
      onChangeLocalization('en');
    }
  }, [personal_account_settings?.lang]);

  return useRoutes([
    {
      path: '/',
      element: <PagesLanding />
    },
    LoginRoutes,
    MainRoutes
  ]);
}
