// types
import { createSlice } from '@reduxjs/toolkit';

import { IItems, IOrderCreate } from '../../types/order-types';

// initial state
const initialState: IOrderCreate = {
  isSendingFromLocker: true,
  dataOrder: {
    description: '',
    orderNumber: '',
    from: '',
    to: '',
    fromPlace: null,
    prepayment: 10,
    toPlace: null,
    tariffs: null,
    resultTariff: null,
    tariff: 0,
    tariffName: '',
    serviceFee: '',
    announcedPrice: '1',
    deliveryCost: '',
    cod: 0,
    amountVAT: '',
    dataCargoPlace: [
      {
        sizes: '',
        barcode: '',
        height: '',
        ownSizes: false,
        items: [
          {
            articul: '',
            cost: '',
            showMore: false,
            cost_vat: '',
            cost_product: '',
            marking: '',
            name: '',
            quantity: '',
            changeMarking: false,
            cashOnDelivery: ''
          }
        ],
        length: '',
        number: '',
        weight: '',
        width: ''
      }
    ]
  },
  dataSender: {
    orderNumber: '',
    name: '',
    phone: '',
    email: '',
    phoneSecond: '',
    provider_name: '',
    provider_inn: '',
    city: '',
    region: '',
    cityInfo: null,
    comment: '',
    country_code: '',
    uid: '',
    vat_rates: [],
    default_map_service: '',
    currency: 'RUB'
  },
  dataReceiver: {
    isSenderProvidesAddress: false,
    isDate: false,
    address: '',
    addressInp: '',
    uid: '',
    company_reg_number: '',
    company_name: '',
    isClient: true,
    brandPoint: '',
    code: '',
    isAddress: false,
    city: '',
    cityInfo: null,
    country: '',
    longitude: 0,
    latitude: 0,
    email: '',
    name: '',
    phone: '',
    pointSend: '',
    country_code: '',
    region: '',
    typeSend: 30,
    timeRange: '',
    deliveryDate: '',
    deliveryTimeStart: '',
    deliveryTimeEnd: '',
    office: '',
    floor: '',
    deliveryComment: ''
  },
  isEditingOrder: false,
  direction_type: 0,
  selected_point_uid: '',
  point_type: '',
  touched_sender: false,
  touched_receiver: false,
  inn: '',
  errorTariff: false
};

// ==============================|| SLICE - MENU ||============================== //

const orderCreate = createSlice({
  name: 'orderCreate',
  initialState,
  reducers: {
    getOrderData(state, action) {
      const items = state.dataOrder.dataCargoPlace.map((cargo) => {
        return cargo.items;
      });

      state.dataOrder.dataCargoPlace = action.payload.dataCargoPlace;

      state.dataOrder.dataCargoPlace = state.dataOrder.dataCargoPlace.map((cargo, i) => {
        return { ...cargo, items: items[i] };
      });

      state.dataOrder.description = action.payload.description;
      state.dataOrder.orderNumber = action.payload.orderNumber;
      state.dataOrder.cod = action.payload.cod;
      state.dataOrder.announcedPrice = action.payload.announcedPrice;
      state.dataOrder.serviceFee = action.payload.serviceFee;
    },
    getSenderData(state, action) {
      state.dataSender = {
        ...state.dataSender,
        ...action.payload.values,
        cityInfo: action.payload?.values?.cityInfo || state.dataSender.cityInfo
      };

      state.dataSender.country_code = action.payload.country_code;
      state.dataSender.region = action.payload.region;
    },
    getCountryCode(state, action) {
      state.dataSender.country_code = action.payload.country_code;
      state.dataSender.region = action.payload.region;
    },
    addTariffResult(state, action) {
      state.dataOrder.resultTariff = action.payload;
    },
    changeIsAddress(state, action) {
      state.dataReceiver.isAddress = action.payload;
    },
    addPointInfo(state, action) {
      state.dataReceiver.uid = action.payload.uid;
      state.dataReceiver.brandPoint = action.payload.brand;
      state.dataReceiver.pointSend = action.payload.point;
    },
    addServiceFee(state, action) {
      state.dataOrder.serviceFee = action.payload;
    },
    addCodeAndBrand(state, action) {
      state.dataReceiver.pointSend = action.payload.code;
      state.dataReceiver.brandPoint = action.payload.brand;
      state.dataReceiver.uid = action.payload.uid;
    },
    addAnnouncedPrice(state, action) {
      state.dataOrder.announcedPrice = action.payload;
    },
    getTariffs(state, action) {
      if (action.payload) {
        state.dataOrder.tariffs = action.payload;
      }
    },
    changeTariff(state, action) {
      state.dataOrder.tariff = action.payload;
    },
    addErrorTariff(state, action) {
      state.errorTariff = action.payload;
    },
    getTariffName(state, action) {
      state.dataOrder.tariffName = action.payload;
    },
    getContact(state, action) {
      state.dataSender.comment = action.payload.comment;
      state.dataSender.orderNumber = action.payload.orderNumber;
      state.dataSender.name = action.payload.name;
      state.dataSender.phone = action.payload.phone;
      state.dataSender.email = action.payload.email;
      state.dataSender.provider_inn = action.payload.inn;
      state.dataSender.provider_name = action.payload.provider_name;
      state.dataSender.city = action.payload.city;
      state.dataSender.region = action.payload.region;
      state.dataSender.country_code = action.payload.country_code;
      state.dataSender.uid = action.payload.uid;
      state.dataSender.vat_rates = action.payload.var_rates;
      state.dataSender.default_map_service = action.payload.default_map_service;
      state.dataSender.currency = action.payload.currency;
      state.inn = action.payload.inn;
    },
    getRecipientInfo(state, action) {
      state.dataReceiver.name = action.payload.name;
      state.dataReceiver.phone = action.payload.phone;
      state.dataReceiver.email = action.payload.email;
      state.dataReceiver.deliveryComment = action.payload.comment;

      state.dataOrder.toPlace = {
        name: action.payload.city,
        national_id: '',
        uid: '',
        region: {
          name: action.payload.region,
          national_id: '',
          uid: '',
          country: {
            name: action.payload.country,
            abbreviation: action.payload.country_code,
            uid: ''
          }
        },
        country: action.payload.country
      };

      state.dataReceiver.city = action.payload.city;
      state.dataReceiver.country_code = action.payload.country_code;
      state.dataReceiver.region = action.payload.region;
      state.dataReceiver.longitude = action.payload.longitude;
      state.dataReceiver.latitude = action.payload.latitude;
      state.dataReceiver.address = action.payload.address;
      state.dataReceiver.office = action.payload.office;
      state.dataReceiver.floor = action.payload.floor;
      state.dataReceiver.company_name = action.payload.company_name || state.dataReceiver.company_name;
      state.dataReceiver.company_reg_number = action.payload.company_reg_number || state.dataReceiver.company_reg_number;
      state.dataReceiver.typeSend = action.payload.type;

      if (action.payload.company_name) {
        state.dataReceiver.isClient = false;
      }

      if (action.payload.desired_delivery_at?.delivery_date) {
        state.dataReceiver.deliveryDate = action.payload.desired_delivery_at?.delivery_date.replaceAll('.', '-') || '';
        state.dataReceiver.isDate = true;
      }

      state.dataReceiver.deliveryTimeStart = action.payload.desired_delivery_at?.delivery_time_start || '';
      state.dataReceiver.deliveryTimeEnd = action.payload.desired_delivery_at?.delivery_time_end || '';
      state.dataReceiver.timeRange = `${action.payload.desired_delivery_at?.delivery_time_start} - ${action.payload.desired_delivery_at?.delivery_time_end}`;

      if (action.payload.type === 40) {
        state.dataReceiver.isAddress = true;
      }
    },
    getCargoCost(state, action) {
      state.dataOrder.dataCargoPlace = action.payload.places.map((el: any) => {
        return {
          sizes: '',
          barcode: el.barcode || '',
          height: el.height / 10 || '',
          ownSizes: true,
          items: el.items.map((item: IItems) => {
            if (!item.cost_vat || !item.articul || !item.marking) {
              return {
                ...item,
                cost_vat: item.cost_vat === null ? '' : item.cost_vat,
                articul: item.articul === null ? '' : item.articul,
                marking: item.marking === null ? '' : item.marking
              };
            }

            return { ...item, showMore: true };
          }),
          length: el.length / 10,
          number: el.number,
          weight: el.weight / 1000,
          width: el.width / 10
        };
      });

      state.dataOrder.prepayment = action.payload.prepayment || 20;
      state.dataOrder.deliveryCost = action.payload.deliveryCost || 0;
      state.dataOrder.announcedPrice = action.payload.announcedPrice || 0;
    },
    addDeliveryCost(state, action) {
      state.dataOrder.deliveryCost = action.payload;
    },
    changeCompanyNameRec(state, action) {
      state.dataReceiver.company_name = action.payload;
    },
    changeCompanyINNRec(state, action) {
      state.dataReceiver.company_reg_number = action.payload;
    },
    changeIsClient(state, action) {
      state.dataReceiver.isClient = action.payload;
    },
    changePrepayment(state, action) {
      state.dataOrder.prepayment = action.payload || 20;
    },
    changeHeight(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].height = action.payload.height;
      }
    },
    changeWidth(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].width = action.payload.width;
      }
    },
    changeLength(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].length = action.payload.length;
      }
    },
    changeWeight(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].weight = action.payload.weight;
      }
    },
    changeOwnSizes(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].ownSizes = action.payload.ownSize;
      }
    },
    changeSizes(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].sizes = action.payload.sizes;
      }
    },
    changeBarcode(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.index]) {
        state.dataOrder.dataCargoPlace[action.payload.index].barcode = action.payload.barcode;
      }
    },
    changeShowMore(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].showMore = action.payload.showMore;
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].marking = '';
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].cost_vat = '';
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].articul = '';
      }
    },
    pushCargoPlace(state, action) {
      state.dataOrder.dataCargoPlace.push(action.payload);
    },
    pushProduct(state, action) {
      state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]?.items.push(action.payload.item as never);
    },
    deleteCargoPlace(state, action) {
      state.dataOrder.dataCargoPlace = state.dataOrder.dataCargoPlace.filter((_, index) => index !== action.payload);
    },
    deleteProduct(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items = state.dataOrder.dataCargoPlace[
          action.payload.indexPlace
        ].items.filter((_, index) => index !== action.payload.index);
      }
    },
    addFromOrder(state, action) {
      state.dataOrder.from = action.payload;
    },
    addToOrder(state, action) {
      state.dataOrder.to = action.payload;
    },
    addFromPlace(state, action) {
      state.dataSender.city = action.payload;
    },
    addTimeRange(state, action) {
      state.dataReceiver.timeRange = action.payload;
    },
    addFromPlaceCity(state, action) {
      state.dataSender.cityInfo = action.payload;
    },
    addNameItem(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].name = action.payload.name;
      }
    },
    isChangeMarking(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].changeMarking = action.payload.changeMarking;
      }
    },
    addCost(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].cost = action.payload.cost;
      }
    },
    addQuantity(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].quantity = action.payload.quantity;
      }
    },
    addArticle(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].articul = action.payload.articul;
      }
    },
    addCostVat(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].cost_vat = action.payload.cost_vat;
      }
    },
    addMarking(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].marking = action.payload.marking;
      }
    },
    addCod(state, action) {
      state.dataOrder.cod = action.payload;
    },
    addToPlace(state, action) {
      state.dataOrder.toPlace = action.payload;
    },
    addRegCountry(state, action) {
      state.dataReceiver.country_code = action.payload.country_code;
      state.dataReceiver.region = action.payload.region;
    },
    addRegCountrySender(state, action) {
      state.dataSender.country_code = action.payload.country_code;
      state.dataSender.region = action.payload.region;
      state.dataSender.cityInfo = action.payload.cityInfo;
    },
    editOrder(state, action) {
      state.isEditingOrder = action.payload;
    },
    changeNameRec(state, action) {
      state.dataReceiver.name = action.payload;
    },
    changeIsDate(state, action) {
      state.dataReceiver.isDate = action.payload;
    },
    changeTypeSend(state, action) {
      state.dataReceiver.typeSend = action.payload;
    },
    changePhoneRec(state, action) {
      state.dataReceiver.phone = action.payload;
    },
    changeEmailRec(state, action) {
      state.dataReceiver.email = action.payload;
    },
    changeCityRec(state, action) {
      state.dataReceiver.city = action.payload.city;
      state.dataReceiver.cityInfo = action.payload.cityInfo;
    },
    changeCountryRec(state, action) {
      state.dataReceiver.country_code = action.payload;
    },
    changeAddressRec(state, action) {
      state.dataReceiver.address = action.payload;
    },
    changeDateRec(state, action) {
      state.dataReceiver.deliveryDate = action.payload;
    },
    changeTimeFromRec(state, action) {
      state.dataReceiver.deliveryTimeStart = action.payload;
    },
    changeTimeToRec(state, action) {
      state.dataReceiver.deliveryTimeEnd = action.payload;
    },
    changeFloorRec(state, action) {
      state.dataReceiver.floor = action.payload;
    },
    changeOfficeRec(state, action) {
      state.dataReceiver.office = action.payload;
    },
    changeCommentRec(state, action) {
      state.dataReceiver.deliveryComment = action.payload;
    },
    changeCommentSender(state, action) {
      state.dataSender.comment = action.payload;
    },
    addDataCargoPlace(state, action) {
      state.dataOrder.dataCargoPlace = action.payload;
    },
    clearOrderCreate(state) {
      Object.assign(state, initialState);
    },
    toggleDirectionType(state) {
      state.direction_type = state.direction_type === 0 ? 2 : 0;
    },
    setDirectionType(state, action) {
      state.direction_type = action.payload;
    },
    setAutoCompleteReceiver(state, action) {
      state.dataReceiver.name = action.payload.name;
      state.dataReceiver.phone = action.payload.phone;
      state.dataReceiver.email = action.payload.email;
      state.dataReceiver.company_reg_number = action.payload.company_reg_number;
      state.dataReceiver.company_name = action.payload.company_name;
    },
    setSelectedPointUid(state, action) {
      state.selected_point_uid = action.payload;
    },
    setPointType(state, action) {
      state.point_type = action.payload;
    },
    setCashOnDelivery(state, action) {
      if (state.dataOrder.dataCargoPlace?.[action.payload.indexPlace]) {
        state.dataOrder.dataCargoPlace[action.payload.indexPlace].items[action.payload.index].cashOnDelivery =
          action.payload.cashOnDelivery;
      }
    },
    setCashOnDeliveryAll(state, action) {
      const itemCashOnDelivery = state.dataOrder.dataCargoPlace.map((cargo) => {
        const newCargo = cargo.items.map((item) => {
          return { ...item, cashOnDelivery: action.payload };
        });

        return { ...cargo, items: newCargo };
      });

      state.dataOrder.dataCargoPlace = itemCashOnDelivery;
    },
    changeSendingFromLocker(state, action) {
      state.isSendingFromLocker = action.payload;
    },
    changeIsSenderProvidesAddress(state, action) {
      state.dataOrder.toPlace = null;
      state.dataReceiver.country_code = '';
      state.dataReceiver.city = '';
      state.dataReceiver.cityInfo = null;
      state.dataReceiver.pointSend = '';
      state.dataReceiver.brandPoint = '';
      state.dataReceiver.uid = '';

      state.dataReceiver.typeSend = !action.payload ? 30 : 20;
      state.dataReceiver.isSenderProvidesAddress = action.payload;
    }
  }
});

export default orderCreate.reducer;

export const {
  getOrderData,
  getSenderData,
  clearOrderCreate,
  addFromPlace,
  addFromPlaceCity,
  addToPlace,
  addRegCountry,
  changeTypeSend,
  editOrder,
  getContact,
  changeOwnSizes,
  addPointInfo,
  changeCommentSender,
  changeCountryRec,
  pushCargoPlace,
  changeShowMore,
  pushProduct,
  changeIsClient,
  deleteCargoPlace,
  changePrepayment,
  deleteProduct,
  addTimeRange,
  addNameItem,
  addArticle,
  addCostVat,
  addMarking,
  addCost,
  addQuantity,
  changeTariff,
  getTariffName,
  changeHeight,
  changeWidth,
  getCargoCost,
  changeLength,
  changeWeight,
  getTariffs,
  addDeliveryCost,
  changeIsAddress,
  addServiceFee,
  changeSizes,
  changeNameRec,
  changePhoneRec,
  changeEmailRec,
  isChangeMarking,
  changeCityRec,
  changeAddressRec,
  addRegCountrySender,
  changeDateRec,
  changeTimeFromRec,
  changeTimeToRec,
  changeCommentRec,
  changeFloorRec,
  changeOfficeRec,
  getRecipientInfo,
  changeCompanyNameRec,
  changeCompanyINNRec,
  changeBarcode,
  addCod,
  addAnnouncedPrice,
  toggleDirectionType,
  setDirectionType,
  setAutoCompleteReceiver,
  setSelectedPointUid,
  setPointType,
  addCodeAndBrand,
  getCountryCode,
  addDataCargoPlace,
  setCashOnDelivery,
  setCashOnDeliveryAll,
  addTariffResult,
  addErrorTariff,
  changeSendingFromLocker,
  changeIsSenderProvidesAddress
} = orderCreate.actions;
