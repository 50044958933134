import { createSlice } from '@reduxjs/toolkit';

import { IUser } from '../../types/user';

const initialState: IUser = {
  login: '',
  type: undefined,
  first_name: '',
  last_name: '',
  patronymic: '',
  phone: '',
  partner_system_id: '',
  company_uid: '',
  attached_device: '',
  uid: '',
  pin: '',
  company_name: '',
  profile_name: '',
  company_logo: '',
  show_frontend_menu: [],
  profiles_for_create: [],
  partner_device_types: [],
  partners_pins: [],
  country_settings: null,
  personal_account_settings: {
    lang: 'en',
    theme: {
      theme_layout: 'ltr',
      theme_mode: 'light',
      color_scheme: 'default',
      layout_width: 'container',
      font_family: 'public-sans'
    }
  }
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getAllUserData(state, action) {
      Object.assign(state, action.payload);
    },
    clearUserDate(state) {
      Object.assign(state, initialState);
    },
    changeLang(state, action) {
      state.personal_account_settings = { ...state.personal_account_settings, lang: action.payload };
    },
    changeTheme(state, action) {
      state.personal_account_settings = { ...state.personal_account_settings, theme: action.payload };
    }
  }
});

export const { getAllUserData, clearUserDate, changeLang } = user.actions;

export default user.reducer;
