// material-ui
// third-party
import { merge } from 'lodash';
import { Theme } from '@mui/material/styles';

import Fab from './Fab';
import Tab from './Tab';
import Chip from './Chip';
import Link from './Link';
import Tabs from './Tabs';
import Alert from './Alert';
import Badge from './Badge';
import Radio from './Radio';
import Button from './Button';
import Dialog from './Dialog';
import Slider from './Slider';
import Switch from './Switch';
import Popover from './Popover';
import Checkbox from './Checkbox';
import TableRow from './TableRow';
import TreeItem from './TreeItem';
// project import
import Accordion from './Accordion';
import InputBase from './InputBase';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import AlertTitle from './AlertTitle';
import ButtonBase from './ButtonBase';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import Pagination from './Pagination';
import Typography from './Typography';
import ButtonGroup from './ButtonGroup';
import CardContent from './CardContent';
import DialogTitle from './DialogTitle';
import TableFooter from './TableFooter';
import Autocomplete from './Autocomplete';
import ListItemIcon from './ListItemIcon';
import ToggleButton from './ToggleButton';
import LoadingButton from './LoadingButton';
import OutlinedInput from './OutlinedInput';
import LinearProgress from './LinearProgress';
import ListItemButton from './ListItemButton';
import PaginationItem from './PaginationItem';
import TablePagination from './TablePagination';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import DialogContentText from './DialogContentText';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Accordion(theme),
    AccordionDetails(theme),
    AccordionSummary(theme),
    Alert(theme),
    AlertTitle(),
    Autocomplete(),
    Badge(theme),
    Button(theme),
    ButtonBase(),
    ButtonGroup(),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    Dialog(),
    DialogContentText(theme),
    DialogTitle(),
    Fab(theme),
    IconButton(theme),
    InputBase(),
    InputLabel(theme),
    LinearProgress(),
    Link(),
    ListItemButton(theme),
    ListItemIcon(theme),
    LoadingButton(),
    OutlinedInput(theme),
    Pagination(),
    PaginationItem(theme),
    Popover(theme),
    Radio(theme),
    Slider(theme),
    Switch(theme),
    Tab(theme),
    TableBody(theme),
    TableCell(theme),
    TableFooter(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    Tabs(),
    ToggleButton(theme),
    TreeItem(),
    Typography()
  );
}
