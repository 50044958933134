import { useSelector } from 'react-redux';

import menuItem from 'menu-items';
// material-ui
import { Box } from '@mui/material';
// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  return (
    <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>
      {menuItem.items.map((item) => {
        return <NavGroup key={item.id} item={item} />;
      })}
    </Box>
  );
};

export default Navigation;
